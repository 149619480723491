@import "./src/assets/scss/styles.scss";

.toast-notification-panel {
  position: fixed;
  top: calc(3.2 * var(--dsf-scale) * 1rem);
  right: calc(3.2 * var(--dsf-scale) * 1rem);
  margin-top: calc(5.76 * var(--dsf-scale) * 1rem);
  width: calc(30.72 * var(--dsf-scale) * 1rem);
  z-index: 1000;

  @media screen and (max-width: 768px) {
    & {
      left: calc(1.6 * var(--dsf-scale) * 1rem);
      width: calc(100vw - (3.2 * var(--dsf-scale) * 1rem));
    }
  }
}
