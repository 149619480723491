@import '@sennder/shell-utilities/dist/style.css';

html,
html .dsf-theme-default {
  font-size: 16px;
  --dsf-scale: 0.625 !important;
}

html.with-plankton,
html.with-plankton .dsf-theme-default {
  font-size: 16px !important;
  --dsf-scale: 0.625 !important;
}

html.with-coral,
html.with-coral .dsf-theme-default {
  font-size: 10px !important;
  --dsf-scale: 1 !important;
}
